import React from "react";
//images

export default function Page404() {
  return (
    <React.Fragment>
      <section id="not-found">
        <div className="not-found-background" />
        <div className="text">
          <h2>404</h2>
          <h3>Sorry! Page Not Found</h3>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";
import codepen_icon from "../images/codepen.png";
import linkedin_icon from "../images/linkedin.svg";
import email_icon from "../images/mail.svg";
import github_icon from "../images/github.svg";

export default function Footer() {
  return (
    <React.Fragment>
      <footer>
        <div className="footer">
          <div className="footerIcons">
            <div className="socialIcon">
              <a href="https://www.linkedin.com/in/melissaxgonzalez/">
                <img src={linkedin_icon} alt="melissazgonzalez on linkedin" />
              </a>
            </div>
            <div className="socialIcon">
              <a href="mailto:melissaxgnz@gmail.com">
                <img src={email_icon} alt="my email is melissaxgnz@gmail.com" />
              </a>
            </div>
            <div className="socialIcon">
              <a href="https://github.com/melissagnzx">
                <img src={github_icon} alt="melissagnzx on github" />
              </a>
            </div>
          </div>
          <p id="credits">Developed and Designed by a Melissa Gonzalez</p>
        </div>
      </footer>
    </React.Fragment>
  );
}

import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import Certificates from "./components/Certificates";
import Home from "./components/Home";
import Footer from "./components/Footer";
import DevConnector from "./components/DevConnector";
import Podcast from "./components/Podcast";
import SeniorProject from "./components/SeniorProject";
import Designs from "./components/Designs";
import UXCase from "./components/UXCase";
import Page404 from "./components/Page404";

function App() {
  return (
    <div>
      <Navbar />
      <Switch>
        <Route exact path="/" activeClassName="active" component={Home} />
        <Route
          exact
          path="/certificates"
          activeClassName="active"
          component={Certificates}
        />
        <Route
          exact
          path="/networking-app"
          activeClassName="active"
          component={DevConnector}
        />
        <Route
          exact
          path="/podcast-app"
          activeClassName="active"
          component={Podcast}
        />
        <Route
          exact
          path="/senior-project"
          activeClassName="active"
          component={SeniorProject}
        />
        <Route
          exact
          path="/sample-designs"
          activeClassName="active"
          component={Designs}
        />
        <Route
          exact
          path="/ux-case-study"
          activeClassName="active"
          component={UXCase}
        />
        <Route component={Page404} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;

import React from "react";
import future_image from "../images/mockups/future.png";
import course_design from "../images/mockups/courses.png";
import darkMode_design from "../images/mockups/DarkMode.jpg";
import developerSite_design from "../images/mockups/developerSite.png";
import email_design from "../images/mockups/email.jpg";
import streaming_design from "../images/mockups/streamingApp.png";
import ModalImage from "react-modal-image";

export default function Mockups() {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="d-flex flex-wrap align-content-center justify-content-center designs">
          <div className="designImg">
            <ModalImage
              small={future_image}
              large={future_image}
              hideDownload={true}
            />
          </div>
          <div className="designImg">
            <ModalImage
              small={course_design}
              large={course_design}
              hideDownload={true}
            />
          </div>
          <div className="designImg">
            <ModalImage
              small={streaming_design}
              large={streaming_design}
              hideDownload={true}
            />
          </div>
          <div className="designImg">
            <ModalImage
              className="design"
              small={darkMode_design}
              large={darkMode_design}
              hideDownload={true}
            />
          </div>

          <div className="designImg">
            <ModalImage small={email_design} large={email_design} />
          </div>

          <div className="designImg">
            <ModalImage
              small={developerSite_design}
              large={developerSite_design}
              hideDownload={true}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import React, { useEffect } from "react";
import Mockups from "../components/Mockups";
export default function Designs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section id="uxoverview">
        <div className="container pageSection mt-5">
          <h4>Sample Designs</h4>

          <div className="row justify-content-center align-items-center mt-5">
            <div className="col-12  col-md-6 col-lg-10 col-xl-8">
              <p>
                I have a passion for UI/UX design and strive to improve my
                design problem solving skills by designing different application
                ideas. These are a few designs that I created using Sketch
                and/or Adobe XD.
              </p>
            </div>
          </div>
        </div>
        <Mockups />
      </section>
    </div>
  );
}

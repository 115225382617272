import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ux_image from "../images/smartHome.jpg";
import socialApp_image from "../images/blogging.jpg";
import podcast_image from "../images/podcastProj.jpg";
import mockups_image from "../images/ux.jpg";
import seniorPrj_img from "../images/students.jpg";
import aboutme_image from "../images/aboutMe.svg";
import grad_image from "../images/grad.svg";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <!-- Main Section --> */}
      <section className="main" id="homePage">
        <div className="mainBackground">
          <div className="coverText">
            <h2>Hi, my name is Melissa.</h2>
            <h4>
              I am an aspiring Front End Developer/Designer based in the Pacific
              Northwest.
            </h4>
          </div>
        </div>
      </section>
      {/* <!-- Overview --> */}
      <section id="overview" className="aboutMe">
        <div className="heading">
          <h2>Overview</h2>
        </div>
        <div className="container content">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-sm-12 col-xs-12 ">
              <img className="svg" src={aboutme_image} alt="developer icon" />
            </div>
            <div className="col-lg-7 col-sm-12 col-xs-12 ">
              <h3>About Me</h3>
              <p>
                My name is Melissa Gonzalez and I am a web application developer
                and software developer, currently living in Sequim, WA. I am
                currently looking to attain a software development position. I
                have a Bachelor of Science in Computer Science from Western
                Washington University, and my main focus and inspiration is
                front-end development. However, I am also very passionate about
                web development and UI/UX design and enjoy working at the
                intersection of both. I am a driven and self-motivated developer
                who is constantly striving to strengthen my skills by working on
                new projects.
              </p>
              <p>
                In my free time, I enjoy blogging,reading,
                <a
                  style={{ color: "rgb(102, 162, 149)" }}
                  href="https://www.instagram.com/melissax.js/"
                >
                  connecting with other developers
                </a>
                , and experimenting with new technologies and techniques.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- second row of content --> */}
        <div className="container about">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-xl-5 col-lg-6 col-md-8 offset-lg-1">
              <div className=" moreAbout">
                <div className="d-sm-flex d-flex flex-column flex-md-column flex-lg-row flex-xl-row flex-sm-column">
                  <h6>Education:</h6>
                  <p>
                    <span style={{ fontWeight: "700" }}>B.S</span> in computer
                    science
                    <br />
                    <span style={{ fontWeight: "700" }}>Minor</span> in user
                    experience design Western <br />
                    Washington University(WWU) - December 2018
                  </p>
                </div>

                <div>
                  <h6>Linkedin:</h6>
                  <p>
                    <a
                      style={{ color: "rgb(102, 162, 149)" }}
                      href="https://www.linkedin.com/in/melissaxgonzalez/"
                    >
                      @melissaxgonzalez
                    </a>
                  </p>
                </div>
                <div>
                  <h6>Medium:</h6>
                  <p>
                    <a
                      style={{ color: "rgb(102, 162, 149)" }}
                      href="https://medium.com/@melissaxgnz"
                    >
                      @melissaxgnz
                    </a>
                  </p>
                </div>
                <div>
                  <h6>Codepen:</h6>
                  <p>
                    <a
                      style={{ color: "rgb(102, 162, 149)" }}
                      href="https://codepen.io/melissagnzx"
                    >
                      @melissagnzx
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-8 col-md-6 col-lg-5 col-xl-4">
              <img id="gradIcon" src={grad_image} alt="graduation cap" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Skills --> */}
      <section id="skills">
        <div id="skillBackground">
          <div className="heading">
            <h2>My Skills</h2>
          </div>

          <div className="skillContainer">
            <div className="skill">
              <i className="fas fa-desktop" />
              <h4>Front End Development</h4>
              <p>HTML, CSS, Sass, JS, Bootstrap, React, and Redux</p>
            </div>
            <div className="skill">
              <i className="fas fa-database" />
              <h4>Back End Development</h4>
              <p>
                Java, My SQL, Firebase, NodeJS, Express, and Browser Dev Tools
              </p>
            </div>
            <div className="skill">
              <i className="fab fa-sketch" />
              <h4>UI/UX Design</h4>
              <p>
                Sketch, Adobe XD, Figma, InVision, Adobe Illustrator, and
                Photoshop
              </p>
            </div>
            <div className="skill">
              <i className="fab fa-windows" />
              <h4>Operating Systems</h4>
              <p>Linux/Unix, Mac OS X, and Windows</p>
            </div>
            <div className="skill">
              <i className="fab fa-git-alt" />
              <h4>Work Flow / IDE Tools</h4>
              <p>
                Git,Command Line(Bash), Trello, IntelliJ, Webstorm, and Visual
                Studio
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Projects --> */}
      <section id="projects">
        <div className="heading">
          <h2>Projects</h2>
        </div>
        <p>
          I am actively working to improve my skills by developing new projects.
          As mentioned before, I enjoy working at the intersection of design and
          development and these projects required developing user-centered
          solutions in order to then develop a functional application or design.
        </p>
        <div id="projectContainer">
          {/* <!-- DevConnector --> */}
          <div className="project">
            <img src={socialApp_image} alt="" />
            <h4>DevConnector</h4>
            <p>Full stack social networking app</p>
            <div className="buttonContainer">
              <a href="https://socialdevelopers.herokuapp.com/">
                <button type="button" className="viewBtn">
                  View
                </button>
              </a>
              <Link to="/networking-app">
                <button className="readBtn">Read More</button>
              </Link>
            </div>
          </div>
          {/* <!-- UX Case --> */}
          <div className="project">
            <img src={ux_image} alt="Smart touch screen tecnology" />
            <h4>Mason Smart Home</h4>
            <p>UX case study for smart screen</p>
            <div className="buttonContainer">
              <Link to="/ux-case-study">
                <button className="readBtn">Read More</button>
              </Link>
            </div>
          </div>
          {/* <!-- "NinjaCow" --> */}
          <div className="project">
            <img src={podcast_image} alt="microphone" />
            <h4>"NinjaCow" Podcast</h4>
            <p>React application for a podcast</p>
            <div className="buttonContainer">
              <a href="https://ninjacowmx-4452c.firebaseapp.com/">
                <button type="button" className="viewBtn">
                  View
                </button>
              </a>
              <Link to="/podcast-app">
                <button className="readBtn">Read More</button>
              </Link>
            </div>
            {/* <!-- Senior Project --> */}
          </div>
          <div className="project">
            <img src={seniorPrj_img} alt="students learning" />
            <h4>"Program Showcase"</h4>
            <p>Undergrad senior project</p>
            <div className="buttonContainer">
              <a href="images/portfolio/pshow.mov">
                <button type="button" className="viewBtn">
                  View
                </button>
              </a>
              <Link to="/senior-project">
                <button className="readBtn">Read More</button>
              </Link>
            </div>
          </div>
          {/* <!-- Design Mockups --> */}
          <div className="project">
            <img src={mockups_image} alt="wireframes" />
            <h4>Design Mockups</h4>
            <p>Sample UI designs</p>
            <div className="buttonContainer">
              <Link to="/sample-designs">
                <button type="button" className="viewBtn">
                  View
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../images/logo.png";
function Navbar() {
  return (
    <nav className="navbar sticky-top navbar-expand-lg navbar-light">
      <HashLink className="navbar-brand" to="/#homePage">
        <img src={logo} alt="Melissa Gonzalez" />
      </HashLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <HashLink className="nav-link" to="/#homePage">
              home
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink className="nav-link" to="/#overview">
              about me
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink className="nav-link" to="/#skills">
              skills
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink className="nav-link" to="/#projects">
              projects
            </HashLink>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/certificates">
              certificates
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;

import React, { useEffect } from "react";
import podcast_imageCover from "../images/podcastCover.jpg";
import podcast_svg from "../images/podcastIcon.svg";
import bootstrap_logo from "../images/bootstrap.png";
import firebase_logo from "../images/firebase.png";
import react_logo from "../images/React.png";
import podcast_logo from "../images/ninjacowLogo.PNG";

export default function Podcast() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      {/* <!-- Main Section --> */}
      <section id="UXcase">
        <img
          src={podcast_imageCover}
          alt="background with microphone"
          style={{ width: "100%", opacity: "0.2" }}
        />

        <div className="text">
          <h2>React Podcast App</h2>
          <h4>"NinjaCow" Podcast</h4>
          <h5>February - April 2020</h5>
        </div>
      </section>
      {/* <!-- Overview --> */}
      <section id="uxoverview">
        <div className="container pageSection mt-5">
          <h4>overview</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-6 col-xl-5 offset-lg-1">
              <p>
                To continue developing and practicing new skills, I began to
                take on small web development projects. This podcast page is an
                example of a React application that I worked on.
              </p>
            </div>
            <div className="col-12  col-md-6 col-lg-5 col-xl-5 mt-5">
              <img
                className="w-75 h-100"
                src={podcast_svg}
                alt="podcast icon"
              />
            </div>
          </div>
        </div>
        <div className="container pageSection mt-5">
          <div className="row justify-content-center align-items-center techIcons">
            <h5>TECHNOLOGIES USED</h5>

            <div className="col-12  col-md-12 col-lg-12 col-xl-12 ">
              <img className=" h-100" src={react_logo} alt="react.js logo" />
              <img className=" h-100" src={firebase_logo} alt="firebase logo" />
              <img
                className=" h-100"
                src={bootstrap_logo}
                alt="bootstrap logo"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center mt-5">
            <a
              className="buttonContainer"
              href="https://ninjacowmx-4452c.firebaseapp.com/"
            >
              <button className="projectButton">View Project</button>
            </a>
          </div>
        </div>

        {/* <!-- PROCESS --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>The Process</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-8 mt-2">
              <p>
                I worked directly with the members of the podcast to talk about
                their needs for the page. I began with wireframing with their
                requirements in mind and then developed higher quality mockups
                on Sketch. Once the designs were approved and contained all the
                necessary information, I began to develop the application in
                React. I was able to develop the site using React hooks and APIs
                to update the site as new episodes were uploaded on their side
                as well as make it responsive.
              </p>
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <img
                className="w-50 h-100"
                src={podcast_logo}
                alt="podcast logo"
              />
            </div>
          </div>
        </div>
        {/* <!-- PROCESS --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>CHallenges</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-8 mt-2">
              <p>
                My biggest challenge working on this project was transitioning
                from React lifecycle methods to React hooks. I took this
                opportunity to practice hooks and understand that functional
                components help reduce the number of stateful or className
                components in an application. Another obstacle I learned to
                overcome was working remotely with the members of the podcast.
                Because of our schedules, finding times to talk and make
                progress was difficult at times but speaking directly and
                organizing tasks was helpful to complete the page in a timely
                manner.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React, { useEffect } from "react";
// Images
import masonCover from "../images/portfolio/masonCover.png";
import splashScreen from "../images/portfolio/splashScreen.gif";
import blueprint from "../images/portfolio/blueprint.gif";
import metrics from "../images/portfolio/metrics.gif";
import calendar from "../images/portfolio/calendar.gif";
import modes from "../images/portfolio/modes.gif";
import ux_cover from "../images/ux.jpg";
import smartHome_svg from "../images/smartHome.svg";
import mobileAudience_svg from "../images/mobileAudience.svg";
import moodBoard from "../images/portfolio/moodBoard.jpg";
import research_image from "../images/portfolio/reasearchMason.png";
import savings_svg from "../images/saving.svg";
import vacaction_story from "../images/portfolio/vacationMode.jpg";
import nightMode_story from "../images/portfolio/nightMode.jpg";
import settingGoals_story from "../images/portfolio/settingGoals.jpg";
import secondGoals_story from "../images/portfolio/settingGoals2.jpg";
import whiteboard_image from "../images/portfolio/whiteboardFlow.jpg";
import userFlow from "../images/portfolio/userFlow.png";
import alpha_testing from "../images/portfolio/alpha.jpg";
import beta_testing from "../images/portfolio/betaTesting.jpg";
import digital_testing from "../images/portfolio/digitalPrototype.jpg";
import presentation_image from "../images/projectPresentation.svg";

export default function UXCase() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <section id="UXcase">
        <img
          src={ux_cover}
          alt="wireframes"
          style={{ width: "100%", opacity: "0.3" }}
        />

        <div className="text">
          <h2>UX Case Study</h2>
          <h4>Ten Gun Design - Edmonds, Washington</h4>
          <h5>February - April 2018</h5>
        </div>
      </section>
      {/* <!-- Overview --> */}
      <section id="uxoverview">
        <div className="heading">
          <h2>Overview</h2>
        </div>
        <div id="container" style={{ marginTop: "45px" }}>
          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-sm-10 col-md-5 col-lg-5 offset-xl-1 col-xl-4 ">
              <div className="right pageSection">
                <p>
                  Ten Gun Design frim from Edmonds, Washington worked with our
                  capstone of the User Experience Minor. They presented us with
                  a fictional company called “Mason Homes”. This company designs
                  and builds prefabricated smart-homes with the intent to reach
                  net zero energy and focus on implementing connected
                  technologies into everyday life.
                </p>
                <h4>The Challenge</h4>
                <p>
                  Our UX challenge was to positively change a homeowners utility
                  consumption through an display of real-time data and create a
                  developing habit-forming product. We worked in
                  multidisciplinary teams to tackle this challenge. Our team of
                  4 represented 3 different disciplines: Graphic Design,
                  Industrial Design and Computer Science(myself).
                </p>
              </div>
            </div>

            <div
              className="col-10 col-sm-10 col-md-6 col-lg-5 col-xl-4 justify-content-center"
              style={{ textAlign: "center" }}
            >
              <img
                className="w-75 h-100"
                src={smartHome_svg}
                alt="smart home
            icon"
              />
            </div>
          </div>
        </div>
        {/* <!-- THE BRIEF --> */}
        <hr className="divider" />
        <div className="container">
          <div className="row justify-content-center pageSection">
            <div className="col-10 col-md-10 col-lg-10 col-xl-10 mt-5">
              <h4>The Brief</h4>
              <p className="mt-3">
                Ten Gun suggested exploring avenues that would motivate users to
                create an actual change in their daily habits regarding utility
                consumption. We were also encouraged to explore innovative
                technology, namely OLED technology. The project also provided an
                opportunity to design an interface that functions within a fully
                connected home seamlessly and practically.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- AUDIENCE DEMOGRAPHIC --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>audience demographic</h4>
          <div className="row justify-content-center align-items-center">
            <div className="col-12  col-md-6 col-lg-5 offset-lg-1 col-xl-5 mt-5">
              <img
                className="w-75 h-100"
                src={mobileAudience_svg}
                alt="different mobile devices"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <p>
                Our target demographic comprises tech-savvy home buyers. They
                are aspirational individuals but have a lack of patience for
                poorly designed technology that wastes their time.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- MARKET RESEARCH --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>Market Research</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-5 col-xl-12 mt-4">
              <img
                className="w-75 h-100"
                src={moodBoard}
                alt="smart screen mood board "
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-10 mt-5">
              <p>
                We began by creating a survey and sending it out to a broad
                audience. We then collected data from over 60 responses. The
                participants showed concern with the environment but they
                ultimately cared about the impact on their wallet when it came
                down to why they would use this application for resource
                management.
              </p>
            </div>
          </div>
          {/* <!-- Second Row content --> */}
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-5">
              <p>
                We began by creating a survey and sending it out to a broad
                audience. We then collected data from over 60 responses. The
                participants showed concern with the environment but they
                ultimately cared about the impact on their wallet when it came
                down to why they would use this application for resource
                management.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 col-xl-6 mt-4">
              <img
                className="w-75"
                src={research_image}
                alt="different smart home technologies"
              />
            </div>
          </div>
        </div>
        {/* <!-- PROPOSED SOLUTION --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>Proposed Solution</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6 mt-5">
              <p>
                It's important to show homeowners the impact their utility usage
                has on the environment AND their wallet. Our research
                participants were interested in tools that track their resource
                consumption and conveniently display real-time results. This
                should monitor all energy and resource consumption in the house,
                displaying this information in a way to motivate them to take
                steps toward reducing their resource usage instead of scaring
                them into it.
              </p>
            </div>
            <div className="col-12 col-md-6 col-xl-5 mt-4">
              <img
                className="w-75 h-100"
                src={savings_svg}
                alt="saving money icon"
              />
            </div>
          </div>
        </div>
        {/* <!-- BENEFITS --> */}
        <hr className="divider" />
        <div className="container">
          <div className="row justify-content-center pageSection">
            <div className="col-10 col-md-10 col-lg-10 col-xl-8 mt-5">
              <h4>Benefit Statement</h4>
              <p className="mt-3">
                A platform that displays all the household resource consumption
                data in one interface. This platform would allow the user to
                monitor and control these resources. The interface can help the
                homeowners save money and resources by providing
                recommendations/presets.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- STORYBOARDS --> */}
        <hr className="divider" />
        <div className="container" style={{ maxWidth: "98vw" }}>
          <div className="row justify-content-center pageSection">
            <div className="col-10 col-md-10 col-lg-8 col-xl-8 mt-5">
              <h4>Storyboards</h4>
              <p className="mt-3">
                We created storyboards to get a better grasp of the features
                that made the most sense to offer and how users would interact
                with each feature. I drafted ideas of scenarios in which certain
                features would be most beneficial to our users such as a
                goal-setting feature, problem-solving scenario, and possible
                house modes. My team then turned those ideas into storyboards.
              </p>
            </div>
            <div className="col-10 col-md-10 col-lg-12 col-xl-6">
              <img
                src={vacaction_story}
                alt="storyboard about putting home on 'vacation' mode"
                className="m-3 w-75 float-xl-right story "
              />
              <img
                src={nightMode_story}
                alt="storyboard about setting house to 'night' mode"
                className="m-3 w-75 float-xl-right story"
              />
            </div>
            <div className="col-10 col-md-10 col-lg-12 col-xl-6">
              <img
                src={settingGoals_story}
                alt="storyboard about setting goals on screen"
                className="m-3 w-75 float-xl-left story"
              />
              <img
                src={secondGoals_story}
                alt="storyboard about screen alerts"
                className="m-3 w-75 story float-xl-left"
              />
            </div>
          </div>
        </div>
        {/* <!-- USER FLOWS --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>User Flows</h4>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-xl-4 mt-4">
              <img
                className="w-75 h-100"
                src={whiteboard_image}
                alt="whitebaord user flow planning"
              />
            </div>

            <div className="col-12 col-md-6 col-xl-6 mt-4">
              <img
                className="w-100 h-100"
                src={userFlow}
                alt="final user flow"
              />
            </div>
          </div>
        </div>
        {/* <!-- TESTING --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>prototyping and user testing</h4>
          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-10 mt-5">
              <p className="mt-3">
                After creating prototypes, I observed how the users were
                interacting with our features. I recorded what was not clear and
                noted the feedback that users provided based on their experience
                with our model. I reported back to my team what I observed
                during testing and what needed to be modified to deliver a
                better experience.
              </p>
            </div>

            <div className="col-12 col-md-4 col-xl-4 mt-4">
              <img
                className="w-100 h-100"
                src={alpha_testing}
                alt="testing paper prototype"
              />
            </div>

            <div className="col-12 col-md-4 col-xl-4 mt-4">
              <img
                className="w-100 h-100"
                src={beta_testing}
                alt="second round of testing on improved prototype"
              />
            </div>
            <div className="col-12 col-md-4 col-xl-4 mt-4">
              <img
                className="w-100 h-100"
                src={digital_testing}
                alt="testing digital prototype"
              />
            </div>
          </div>
        </div>
        {/* <!-- SOLUTION --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>Solution</h4>
          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-10 mt-5">
              <p className="mt-3">
                We arrived at our solution after lots of testing and
                prototyping. The feedback from our testing subjects gave us the
                information we needed to redesign and reorganize our decisions.
                What is unique about this solution is its 'mode' settings that
                automatically trigger responses in the home that will save the
                homeowner money instantly. <br />
                Most participants showed interest in a pre-installed display in
                the kitchen where most home appliances exist. From this display,
                they wanted to see in-depth data and easy to interpret
                information/graphics.
              </p>
            </div>
          </div>
        </div>
        <div className="gifs">
          <div>
            <img src={masonCover} alt="" />
          </div>

          <div>
            <img src={splashScreen} alt="" />
          </div>
          <div>
            <img src={blueprint} alt="" />
          </div>
          <div>
            <img src={calendar} alt="" />
          </div>
          <div>
            <img src={metrics} alt="" />
          </div>
          <div>
            <img src={modes} alt="" />
          </div>
        </div>
      </section>
      {/* <!-- PRESENTATION --> */}
      <hr className="divider" />
      <div className="container-fluid pageSection">
        <h4>Presentation</h4>

        <div className="row justify-content-center align-items-center no-gutters">
          <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-5">
            <p>
              We presented our project at Ten Gun Design studio in Edmonds,
              Washington. Everyone was supportive of the mode concept and
              appreciated the idea that users could set modes easily and in
              advance through the calendar feature. This aspect of the project
              was important to our overall design. We were pleased with the
              value they saw in our system design.{" "}
            </p>
            <p>
              Presenting at the studio allowed us to receive constructive
              feedback. One thing they suggested was building out the blueprint
              of the house to show the abilities to tap into each room and
              control specific appliances. They also pointed out that some rooms
              may naturally consume more energy than others and asked how we
              could make sure that users knew of which rooms needed more
              attention. We can see a potential solution to this problem by
              creating an algorithm that considers that issue and "weighs" each
              room differently. Most trafficked rooms would be weighted with the
              fact that they are used every day.
            </p>
            <p>
              Overall, this experience was extremely valuable. To work with Ten
              Gun Design and work from within a multidisciplinary UX team has
              helped me develop and design user-centered projects.
            </p>
          </div>
          <div className="col-12 col-md-6 col-xl-5 mt-4">
            <img
              className="w-75 h-100"
              src={presentation_image}
              alt="presentation icon"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

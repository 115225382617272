import React from "react";
import connecting_svg from "../images/connecting.jpg";
import blog_svg from "../images/undraw_blog_anyj.svg";
import mongo_logo from "../images/mongo.png";
import node_logo from "../images/node.png";
import react_logo from "../images/React.png";

export default function DevConnector() {
  return (
    <React.Fragment>
      <section id="UXcase">
        <img
          src={connecting_svg}
          alt="background with microphone"
          style={{ width: "100%", opacity: "0.15" }}
        />

        <div className="text">
          <h2>MERN Application</h2>
          <h4>"DevConnector" Blog App</h4>
          <h5>December 2019</h5>
        </div>
      </section>
      {/* <!-- Overview --> */}
      <section id="uxoverview">
        <div className="container pageSection mt-5">
          <h4>overview</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-6 col-xl-5 offset-lg-1">
              <p>
                “DevConnector” is a social media application for developers.
                Although the idea is simply for practice purposes only, it did
                require implementing all REST API requests. It resembels a blog
                where a developer can create their own account, browse through
                their home feed which contains recent posts, write their own
                post, share, and comment on other user’s posts and respond to
                their own.
              </p>
            </div>
            <div className="col-12  col-md-6 col-lg-5 col-xl-5 mt-5">
              <img className="w-75 h-100" src={blog_svg} alt="blog icon" />
            </div>
          </div>
        </div>
        <div className="container pageSection mt-5">
          <div className="row justify-content-center align-items-center techIcons">
            <h5>TECHNOLOGIES USED</h5>

            <div className="col-12  col-md-12 col-lg-12 col-xl-12 ">
              <img h-100="true" src={mongo_logo} alt="mongodb logo" />
              <img h-100="true" src={node_logo} alt="node.js logo" />
              <img h-100="true" src={react_logo} alt="react.js logo" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center mt-5">
            <a
              className="buttonContainer"
              href="https://socialdevelopers.herokuapp.com/"
            >
              <button className="projectButton">View Project</button>
            </a>
          </div>
        </div>

        {/* <!-- PROCESS --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>The Process</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-8 mt-2">
              <p>
                I began by implementing the back end using Node and Express as
                well as Postman to test my API requests. I also setup my
                database using MongoDB and connected it to my app. Once I was
                able to create an account, post, and comments, I began to
                implement the front end with React. I did use Redux to simplify
                state which was challenging but in the end it helped keep track
                of all user’s and their data such as comments and the number of
                likes on a comment. I relied on React and Redux browser tools to
                do testing and debug whenever I came across any errors. Although
                the UI is not as pixel perfect as I would’ve liked, I reached my
                goal of implementing the application’s functionality correcting
                following full stack development tools.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- PROCESS --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>CHallenges</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-8 mt-2">
              <p>
                The greatest challenge for me while implementing this
                application was developing the back end. I have had more
                experience in the design and front end development of previous
                projects and therefore have a better grasp of how to resolve
                front end issues however, REST APIs was something that I
                struggled with but luckly had enough practice and requests to
                write that by the end I understood the process a lot better than
                when I started.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

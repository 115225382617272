import React, { useEffect } from "react";
import seniorPrj_Cover from "../images/programShowCover.jpg";
import project_icon from "../images/portfolio/projectShowcase.jpg";
import logo from "../images/portfolio/nodejsLogo.png";
import node_logo from "../images/node.png";
import mongo_logo from "../images/mongo.png";
import nginx_logo from "../images/portfolio/nginxLogo.png";
import myRole from "../images/myRole.svg";
import projectProposal from "../images/portfolio/project-proposal.pdf";
import designDesc from "../images/portfolio/SDD.pdf";
import softwareReq from "../images/portfolio/SRS.pdf";

export default function SeniorProject() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      {/* <!-- Main Section --> */}
      <section id="UXcase">
        <img
          src={seniorPrj_Cover}
          alt="background with someone typing"
          style={{ width: "100%", opacity: "0.2" }}
        />
        <div className="text">
          <h2>Full Stack Senior Project</h2>
          <h4>"ProgramShowcase"</h4>
          <h5>2017-2018</h5>
        </div>
      </section>
      {/* <!-- Overview --> */}
      <section id="uxoverview">
        <div className="container pageSection mt-5">
          <h4>overview</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <p>
                The programs that someone might code as a programming beginner
                can have interesting visual outputs. Python is a popular
                language that is used to teach programming. It's libraries be
                used to create fun programs with colorful or dynamic visuals.
                However, Python requires some knowledge of a terminal/console to
                execute a program which is not a common skill. Unless you code
                often, it's a skill that not everyone has.
              </p>
              <p>
                Dr. Filip Jagodzinski, a computer science faculty member,
                teaches a summer coding camp. He challenged my team to find a
                way to allow his students to share their first programs with
                their family/friends who have little to no coding experience.
              </p>
            </div>
            <div className="col-12  col-md-6 col-lg-5 col-xl-5 mt-5">
              <img
                className="w-75 h-100"
                src={project_icon}
                alt="laptop with project on screen"
              />
            </div>
          </div>
        </div>
        <div className="container pageSection mt-5">
          <div className="row justify-content-center align-items-center techIcons">
            <h5>TECHNOLOGIES USED</h5>

            <div className="col-12  col-md-12 col-lg-12 col-xl-12 ">
              <img className="h-100" src={logo} alt="node.js logo" />
              <img src={node_logo} alt="node.js logo" />
              <img src={mongo_logo} alt="mongodb logo" />
              <img src={nginx_logo} alt="nginx logo" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center mt-5">
            <a className="buttonContainer" href="images/portfolio/pshow.mov">
              <button className="projectButton">WATCH DEMO</button>
            </a>
          </div>
        </div>

        {/* <!-- PROPOSED SOLUTION --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>Proposed solution</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-8 mt-2">
              <p>
                We decided that a possible solution to this problem was to
                create a public site where programmers could execute their
                programs through the site directly. We would develop a space
                where they can upload directly onto the site without access to
                the program's source code once uploaded. To provide users with
                this feature we knew incorporating an emulator would be
                necessary.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- MY ROLE --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>My Role</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-6 mt-2">
              <p>
                The "Program Showcase" team comprised of 3 members. A member
                focused on the back end, another focused on designing and
                connecting the database and someone focused on the front end. I
                took on responsiblity for the front end functionality such as:
              </p>
              <p>
                UI Design and Responsiveness (HTML/CSS/Bootstrap) User forms and
                error handling (JavaScript) Understanting back end functionality
                to correctly manage data on front end. Organize tasks using
                Trello Collaborating remotely with team using Git (branches).
              </p>
              <p>
                I also wrote and organized project documentation such as project
                scope, requirements, and a user manual for future teams to take
                over and continue to implement new features.
              </p>
            </div>
            <div className="col-10 col-md-10 col-lg-10 col-xl-5 mt-2">
              <img
                className="w-75 h-100"
                src={myRole}
                alt="person coding in front of three screens"
              />
            </div>
          </div>
        </div>
        {/* <!-- CHALLENGES --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>Challenges</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-8 mt-2">
              <p>
                The biggest challenge overall was learning new technologies. Our
                courses had been heavily focused on backend development,
                therefore, adapting to front-end tools such as Node.js and
                JavaScript was difficult for all team members. This learning
                curve also made it difficult for us to decide on a stack.
                However, after researching tools and assigning roles, we each
                focused on one area of the project sand set up Trello to
                organize our tasks, and created individual branches to better
                manage our source code.
              </p>
              <p>
                As the front-end developer for my team, I was involved in all
                stages of the project, from creating our site’s UI design and
                integrating ideas from my team members to understanding how
                back-end development worked to efficiently develop front-end
                features. It was a challenge to code in a new language such as
                Javascript but was able to implement the required front-end
                features along with HTML/CSS, and Bootstrap. It was also
                challenging to document how my features were implemented for my
                team members to quickly understand for their implementation but
                I adapted to technical writing and making sure all documentation
                was clear and concise.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- OUTCOME --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>Outcome</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-8 mt-2">
              <p>
                After a year of working as a team, we successfully turned in the
                first version of this project. We were able to implement the
                main features which included: creating an account, uploading a
                Python program, executing a program on the emulator, searching
                for a user's project by title, and signing in/out. I also
                incorporated other features like logging out/tracking user
                sessions that felt necessary for the first version of this
                project.
              </p>
              <p>
                Finally, we presented our project to the computer science
                department of WWU. We gave a demo and explained its overall
                purpose. We also received positive feedback that we were able to
                document for the team creating future versions.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- PROJECT DOCUMENTATION --> */}
        <hr className="divider" />
        <div className="container pageSection">
          <h4>Final Project Documentation</h4>

          <div className="row justify-content-center align-items-center">
            <div className="col-10 col-md-10 col-lg-10 col-xl-3 mt-2 d-flex justify-content-center">
              <a href="images/portfolio/pshow.mov">
                <button className="projectButton">Watch Demo</button>
              </a>
            </div>
            <div className="col-10 col-md-10 col-lg-10 col-xl-4 mt-2">
              <h6>Links to Documentation</h6>
              <div>
                <a
                  className="h-100 w-100"
                  href={projectProposal}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Project Proposal
                </a>
              </div>
              <div>
                <a
                  className="h-100 w-100"
                  href={designDesc}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Software Design Description
                </a>
              </div>
              <div>
                <a
                  className="h-100 w-100"
                  href={softwareReq}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Software Requirement Specification
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

import React from "react";
import certficate_svg from "../images/certificate.svg";
import design_certificate from "../images/portfolio/Cert1.png";
import algorithm_certificate from "../images/portfolio/Cert2.png";

import frontend_certificate from "../images/portfolio/Cert3.png";
import seo_certificate from "../images/portfolio/SEO_Certificate.png";
export default function Certificates() {
  return (
    <div>
      <section id="uxoverview">
        <div className="container pageSection mt-5">
          <h4>Certificates</h4>

          <div className="row justify-content-center align-items-center mt-5">
            <div className="col-12  col-md-6 col-lg-10 col-xl-8">
              <p>
                In order to continue to develop my front end skills, I often
                practice on sites such as freeCodeCamp or Google online learning
                courses.
              </p>
              <img
                className="w-50 h-100"
                src={certficate_svg}
                alt="certificate icon"
              />
            </div>
          </div>
        </div>
        <div id="certificates">
          <div>
            <img
              className="w-100"
              src={design_certificate}
              alt="freeCodeCamp responsive design certificate"
            />
          </div>
          <div>
            <img
              className="w-100"
              src={algorithm_certificate}
              alt="freeCodeCamp JavaScript algorithm and data structures certificate"
            />
          </div>
          <div>
            <img
              className="w-100"
              src={frontend_certificate}
              alt="freeCodeCamp front end library certificate"
            />
          </div>
          <div>
            <img
              className="w-100"
              src={seo_certificate}
              alt="Google SEO certificate"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
